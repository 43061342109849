import { Link } from "gatsby"
import React, { Fragment } from "react"
import HutMap from "../components/hutMap/hutMap"
import Layout from "../components/layout/layout"
import { GeolocationContext } from "../AppState"
import UHCWhiteLogo from "../assets/images/urban-hut-club-white.svg"
import SEO from "../components/seo"

export default function Mappy() {
  if (typeof window === "undefined") {
    return null
  }

  return (
    <Layout title="Map" fullWidth={true}>
    <GeolocationContext.Consumer>
      {context => {
        const { foundHuts } = context
        return (
          <Fragment>
            <SEO title="Map" />
            <Link to="/">
              <img className="map__uhc-logo" src={UHCWhiteLogo} />
            </Link>
            <HutMap
              height="100vh"
              foundHuts={foundHuts}
              bounds={[[174.65, -41.0], [175.3, -41.0]]}
            />
          </Fragment>
        )
      }}
    </GeolocationContext.Consumer>
    </Layout>
  )
}
